<template>

    <Sidebar v-model:visible="ShowCharts" position="right" class="p-sidebar-md">
        <template v-for="[key, value] of Object.entries(Charts)" v-bind:key="key">
            <h3>{{value.Name}}</h3>
            <Chart type="pie" :data="value" :options="chartOptions" v-if="Loading===false"/>
            <ProgressSpinner v-else/>
        </template>
    </Sidebar>

    <div class="p-p-3">
    <h3>Top Mimetypes</h3>

    <DataTable :value="MimeTypes" sortField="Size" :sortOrder="-1">
        <Column field="Name" header="MimeType" :sortable="true" :resizableColumns="true" headerStyle="width: 50rem">
            <template #body="s">
                <span v-tooltip.bottom="s.data.Name" class="truncator" >{{ s.data.Name }}</span>
            </template>
        </Column>
        <Column field="Count" header="Files count" :sortable="true"/>
        <Column field="Size" header="Files size" :sortable="true">
            <template #body="s">
                {{ printFormat("filesize", s.data.Size, false) }}
            </template>
        </Column>
        <Column header="% of total">
            <template #body="s">
                {{Math.round(s.data.Size / TotalSize * 100)>0?Math.round(s.data.Size / TotalSize * 100):"-"}}
            </template>
        </Column>
        <template #header>

            <div class="p-d-flex p-jc-between">
                Total file size {{ printFormat("filesize", TotalSize, false) }}
                <span class="p-input-icon-left">
                    <Button icon="pi pi-chart-bar" @click="showCharts" />
                </span>
            </div>


        </template>
    </DataTable>

    <h3>Top 300 files by size</h3>
    <DataTable :value="TopFiles"  :paginator="true" :rows="30"  :resizableColumns="true" v-model:filters="filters" filterDisplay="row">
        <Column field="Name" header="Filename" headerStyle="width: 50rem" :showFilterMenu="false">
            <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="`Search by name - ${filterModel.matchMode}`" v-tooltip.top.focus="'Hit enter key to filter'"/>
            </template>
        </Column>
        <Column field="Size" header="Size" :sortable="true">
            <template #body="s">
                {{ printFormat("filesize", s.data.Size, false) }}
            </template>
        </Column>
        <Column header="With Overheads">
            <template #body="s">
                {{ printFormat("filesize", s.data.Size * 1.6, false) }}
            </template>
        </Column>
        <Column header="% of total">
            <template #body="s">
                {{Math.round(s.data.Size / TotalSize * 100)>0?Math.round(s.data.Size / TotalSize * 100):"-"}}
            </template>
        </Column>

        <template #header>
            The total size of the first 300 files is {{ printFormat("filesize", TotalTopSize, false) }}, which is <b>{{ Math.round(TotalTopSize / TotalSize * 100) }}%</b> of the total size of all files {{ printFormat("filesize", TotalSize, false) }}
        </template>
    </DataTable>
    </div>
</template>

<script>
import MetricService from "@/services/metrics";
import Chart from "primevue/chart";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Sidebar from "primevue/sidebar";
import ProgressSpinner from "primevue/progressspinner";
import {FilterMatchMode} from 'primevue/api';
// import 'chartjs-plugin-colorschemes';


export default {
    name: "DamData",
    components: {Chart, DataTable, Column, Sidebar, ProgressSpinner},
    data: function () {
        return {
            Loading: true,
            ShowCharts:false,
            filters: {
                'Name': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            chartOptions: {
                animation: {duration: 0},
                legend: {display: false},
                plugins: {
                    // colorschemes: {
                        // scheme: 'brewer.Paired12'
                    // }
                }
            },
            Account: null,
            Charts: {
                IsImage: {Name:""},
                IsJunk: {Name:""}
            },
            MimeTypes: [],
            TotalSize:0,
            TotalTopSize:0,
            TopFiles: []
        }
    },
    service: null,
    computed: {
    },
    created() {
        this.service = new MetricService()
    },
    mounted: function () {
        this.Account = this.$router.currentRoute.value.params.account;
        this.loadData()
    },
    methods: {
        showCharts: function () {
            for (let [key, ] of Object.entries(this.Charts)) {
                this.service.getDamChart(this.Account, key).then(data => {
                    let l = data.XAxis
                    let d = data.YAxis

                    this.Charts[key] ={
                        Name:data.Name,
                        labels: l,
                        datasets: [{borderWidth: 0, data: d}]}
                })
                this.Loading = false
            }

            this.ShowCharts = true

        },

        loadData: function () {
            this.service.getDamMimetypes(this.Account).then(data => {
                this.MimeTypes = data
                data.forEach(o=>{this.TotalSize += o.Size})
            })

            this.service.getDamTop300(this.Account).then(data => {
                this.TopFiles = data
                data.forEach(o=>{this.TotalTopSize += o.Size})
            })
        }
    }
}
</script>